import { AnchorProvider, Program } from "@project-serum/anchor";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import * as anchor from '@project-serum/anchor';
import { FC, useCallback, useEffect, useState } from "react";
import { programId } from "../program/constants";
import NotificationMessage from "./NotificationMessage";
import { AnchorEscrow, IDL } from "../program/anchorEscrow";

const ReservartionWithCode: FC = () => {

    const { connection } = useConnection();
    const [errorMessage, setErrorMessage] = useState("");
    const [infoMessage, setInfoMessage] = useState("");
    const { publicKey } = useWallet();
    const [referral, setReferral] = useState("");
    const [fee, setFee] = useState(0);
    const [amount, setAmount] = useState(0);
    // const [referralAccount, setReferralAccount] = useState(null);

    const wallet = useAnchorWallet();
    // 
    // const escrowAccount = new PublicKey(escrow);

    const getProvider = function () {
        return new AnchorProvider(connection, wallet as anchor.Wallet, { "preflightCommitment": "processed" })
    }

    useEffect(() => {
        setTimeout(() => {
            setErrorMessage("");
        }, 5000);
    }, [errorMessage]);

    useEffect(() => {
        setTimeout(() => {
            setInfoMessage("");
        }, 5000);
    }, [infoMessage]);

    const onClick = useCallback(async () => {
        try {

            if (!publicKey) throw new WalletNotConnectedError();

            anchor.setProvider(getProvider());

            const program = new Program<AnchorEscrow>(IDL, new PublicKey(programId), anchor.getProvider());

            const _allReferralAccount = await program.account.referralAccount.all();

            let _referral: PublicKey | null = null;
            let _referralAccount: PublicKey | null = null;

            _allReferralAccount.forEach(element => {
                if (element.account.referralKey.toBase58() === referral) {
                    _referralAccount = element.publicKey;
                    _referral = element.account.referralKey;
                }
            });

            if (_referral !== null && _referralAccount !== null) {
                await program.rpc.updateReferral(
                    new anchor.BN(fee * LAMPORTS_PER_SOL),
                    new anchor.BN(amount * LAMPORTS_PER_SOL),
                    {
                        accounts: {
                            initializer: publicKey,
                            referral: _referral,
                            referralAccount: _referralAccount
                        },
                        signers: []
                    })
            } else {
                let _newReferralAccount = anchor.web3.Keypair.generate();

                await program.rpc.initializeReferral(
                    new anchor.BN(fee * LAMPORTS_PER_SOL),
                    new anchor.BN(amount * LAMPORTS_PER_SOL),
                    {
                        accounts: {
                            initializer: publicKey,
                            referralAccount: _newReferralAccount.publicKey,
                            referral: new PublicKey(referral),
                            systemProgram: anchor.web3.SystemProgram.programId
                        },
                        instructions: [
                            await program.account.referralAccount.createInstruction(_newReferralAccount),
                        ],
                        signers: [_newReferralAccount],
                    },
                );
            }
            setInfoMessage("Program updated");
        } catch (error: any) {
            if (error.message)
                setErrorMessage(error.message);

            console.log(error);
        }
        // eslint-disable-next-line
    }, [publicKey, setErrorMessage, setInfoMessage, infoMessage, errorMessage, referral, fee, amount]);

    return (
        <>
            <NotificationMessage errorMessage={errorMessage} infoMessage={infoMessage} />
            <div
                className="lg:space-x-5 item-center flex flex-col text-left items-center mt-10">
                <button className="btn btn-active btn-secondary">Reservation with referral</button>
                <div
                    className="lg:space-x-5 lg:flex lg:flex-row item-center lg:-mx-4 flex flex-col-reverse text-center lg:text-left">
                    <div
                        className="w-96 flex-col items-center z-10 space-y-5 rounded-md bg-slate-900 p-10">
                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                                <span className="text-lg">Referral</span>
                            </label>
                            <input type="text" placeholder="3rSLM..."
                                value={referral} className="input input-bordered w-full max-w-xs"
                                onChange={(e) => setReferral(e.target.value)} />
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="text-lg">Fee</span>
                            </label>
                            <div
                                className="group flex w-full items-center rounded-lg bg-gray-100/30 text-white focus:outline-none">

                                <p className="rounded-l-lg bg-gray-400 px-4 py-3 text-lg text-black">
                                    SOL
                                </p>
                                <input
                                    type="number"
                                    step={0.01}
                                    className="w-full rounded-lg bg-transparent px-4 py-3 text-gray-300  transition duration-200 focus:outline-none group-hover:opacity-100"
                                    placeholder="Enter Amount"
                                    value={fee}
                                    onChange={(e) => { setFee(parseFloat(e.target.value)) }}
                                />
                            </div>
                        </div>
                        <div className="form-control">
                            <label className="label">
                                <span className="text-lg">Price</span>
                            </label>
                            <div
                                className="group flex w-full items-center rounded-lg bg-gray-100/30 text-white focus:outline-none">

                                <p className="rounded-l-lg bg-gray-400 px-4 py-3 text-lg text-black">
                                    SOL
                                </p>
                                <input
                                    type="number"
                                    step={0.01}
                                    className="w-full rounded-lg bg-transparent px-4 py-3 text-gray-300  transition duration-200 focus:outline-none group-hover:opacity-100"
                                    placeholder="Enter Amount"
                                    value={amount}
                                    onChange={(e) => { setAmount(parseFloat(e.target.value)) }}
                                />
                            </div>
                        </div>
                        <button
                            onClick={onClick}
                            className="w-full rounded-lg border-cyan-500 border-2 hover:border-2 bg-cyan-500 py-3 text-xl font-bold hover:bg-cyan-600 hover:border-cyan-600">
                            <span className="text-black">Save</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReservartionWithCode;