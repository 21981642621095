import { Icon } from "@iconify/react";
import { FC } from "react";
import { Alert } from "react-daisyui";

const NotificationMessage: FC<NotificationType> = (props) => {

    return (
        <div className="z-20 mt-20 fixed">
            <Alert className={`w-full alert alert-error shadow-lg ${props.errorMessage !== "" ? "" : "invisible absolute"}`}
                status="error"
                icon={<Icon icon="bxs:error-circle" width={16} height={16} />}
            >
                {props.errorMessage}
            </Alert>
            <Alert className={`w-full alert alert-info shadow-lg ${props.infoMessage !== "" ? "" : "invisible absolute"}`}
                status="info"
                icon={<Icon icon="ic:round-security-update-good" width={16} height={16} />}
            >
                {props.infoMessage}
            </Alert>
        </div>
    )
}

interface NotificationType {
    errorMessage: String,
    infoMessage: String
}

export default NotificationMessage;