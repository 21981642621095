import { AnchorProvider } from "@project-serum/anchor";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey, Transaction } from "@solana/web3.js";
import * as anchor from '@project-serum/anchor';
import { FC, useCallback, useEffect, useState } from "react";
import NotificationMessage from "./NotificationMessage";
import { apyTokenId } from "../program/constants";
import { getOrCreateAssociatedTokenAccount } from "../utils/getOrCreateAssociatedTokenAccount";
import { createMintToInstruction, TOKEN_PROGRAM_ID } from "@solana/spl-token";

const TokenMint: FC = () => {
    const { connection } = useConnection();
    const [errorMessage, setErrorMessage] = useState("");
    const [infoMessage, setInfoMessage] = useState("");
    const { publicKey, signTransaction } = useWallet();
    const [amount, setAmount] = useState(0);
    const [receiver, setReceiver] = useState("");

    useEffect(() => {
        setTimeout(() => {
            setErrorMessage("");
        }, 5000);
    }, [errorMessage]);

    useEffect(() => {
        setTimeout(() => {
            setInfoMessage("");
        }, 5000);
    }, [infoMessage]);

    const wallet = useAnchorWallet();

    const apyToken = new PublicKey(apyTokenId);

    const getProvider = function () {
        return new AnchorProvider(connection, wallet as anchor.Wallet, { "preflightCommitment": "processed" })
    }



    const onClick = useCallback(async () => {
        try {

            if (!publicKey || !signTransaction) throw new WalletNotConnectedError();

            const _receiverPublicKey = new PublicKey(receiver);

            anchor.setProvider(getProvider());

            const mintAccount = await getOrCreateAssociatedTokenAccount(
                connection,
                publicKey,
                apyToken,
                publicKey,
                signTransaction
            )

            const receivertokenAccount = await getOrCreateAssociatedTokenAccount(
                connection,
                publicKey,
                apyToken,
                _receiverPublicKey,
                signTransaction
            )

            const transaction = new Transaction().add(
                createMintToInstruction(
                    mintAccount.mint, // mint
                    receivertokenAccount.address, // dest
                    publicKey,
                    amount,
                    [],
                    TOKEN_PROGRAM_ID
                )
            )

            const blockHash = await connection.getRecentBlockhash()
            transaction.feePayer = await publicKey
            transaction.recentBlockhash = await blockHash.blockhash
            const signed = await signTransaction(transaction)

            await connection.sendRawTransaction(signed.serialize())

            setInfoMessage("Program updated")

        } catch (error: any) {
            if (error.message)
                setErrorMessage(error.message);

            console.log(error);
        }
        // eslint-disable-next-line
    }, [publicKey, signTransaction, setErrorMessage, setInfoMessage, infoMessage, errorMessage, receiver, amount]);


    return (
        <>
            <NotificationMessage errorMessage={errorMessage} infoMessage={infoMessage} />

            <div
                className="lg:space-x-5 item-center flex flex-col text-left items-center mt-10">

                <button className="btn btn-success btn-primary">TOKEN MINT</button>
                <div
                    className="lg:space-x-5 lg:flex lg:flex-row item-center lg:-mx-4 flex flex-col-reverse text-center lg:text-left">
                    <div
                        className="w-96 flex-col items-center z-10 space-y-5 rounded-md bg-slate-900 p-10">
                        <div className="form-control">
                            <div className="form-control w-full max-w-xs">
                                <label className="label">
                                    <span className="text-lg">Receiver</span>
                                </label>
                                <input type="text" placeholder="3rSLM..."
                                    value={receiver} className="input input-bordered w-full max-w-xs"
                                    onChange={(e) => setReceiver(e.target.value)} />
                            </div>
                        </div>

                        <div className="form-control w-full max-w-xs">
                            <label className="label">
                                <span className="text-lg">Amount</span>
                            </label>
                            <input value={amount}
                                onChange={(e) => { setAmount(parseInt(e.target.value)) }}
                                type="number"
                                placeholder="2"
                                min={0}
                                max={9}
                                className="input input-bordered w-full max-w-xs" />
                        </div>
                        <button
                            onClick={onClick}
                            className="w-full rounded-lg border-cyan-500 border-2 hover:border-2 bg-cyan-500 py-3 text-xl font-bold hover:bg-cyan-600 hover:border-cyan-600 gap-2">
                            <span className="text-black">Send</span>
                            
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TokenMint;