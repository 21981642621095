import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { FC } from "react";
import Reservartion from "./Reservation";
import ReservartionWithCode from "./ReservationWithCode";
import TokenMint from "./TokenMint";

const Home: FC = () => {

    return (
        <>
            <WalletMultiButton className="btn btn-primary z-20" />
            <div className="container px-4 mx-auto text-center justify-center min-h-screen flex">

                <div className="w-full lg:flex flex-wrap">
                    <Reservartion />
                    <ReservartionWithCode />
                    <TokenMint />
                </div>
            </div>
        </>
    );
};

export default Home;